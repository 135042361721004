import React from "react";
import { Input, Label, Slider } from "./styles";

interface Props {
  onChange?(): void;
  checked?: boolean;
  disabled?: boolean;
}

export function Switch({
  onChange = () => {},
  checked = false,
  disabled = false,
}: Props) {
  return (
    <Label>
      <Input
        disabled={disabled}
        onChange={onChange}
        type="checkbox"
        checked={checked}
      />
      <Slider disabled={disabled} />
    </Label>
  );
}
