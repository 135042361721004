import React, { useState } from "react";
import {
  NotificationPreference,
  NotificationSourcePreference,
  useUpdateUserNotificationPreferenceMutation,
} from "services/graphql";
import Flex from "app/components/Flex";
import { NotificationToggle } from "../NotificationToggle";
import {
  Wrapper,
  OptionsDiv,
  OptionsWrapper,
  Preference,
  EditLink,
} from "../styles";

const PUSH_NOTIFICATIONS_ACTIVITIES = [
  {
    label: "Likes",
    sourcePreference: NotificationSourcePreference.PushUgcReaction,
  },
  {
    label: "Comments",
    sourcePreference: NotificationSourcePreference.PushUgcComment,
  },
];

interface Props {
  notificationPreferences: NotificationPreference[];
}

export function PushNotificationActivitySetting({
  notificationPreferences,
}: Props) {
  const [
    updateUserNotificationPreference,
  ] = useUpdateUserNotificationPreferenceMutation();
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Flex flexDirection="column">
      <Wrapper>
        <div>
          <Preference isEditing={isEditing}>
            <strong>Activity:</strong> Likes and Comments
          </Preference>
        </div>
        <EditLink onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? "Close" : "Edit"}
        </EditLink>
      </Wrapper>
      {isEditing && (
        <OptionsDiv>
          <OptionsWrapper>
            {PUSH_NOTIFICATIONS_ACTIVITIES.map(option => {
              const sourcePreference = notificationPreferences?.find(
                preference => preference.source === option.sourcePreference
              );
              const isEnabled = sourcePreference?.enabled;
              return (
                <NotificationToggle
                  key={option.sourcePreference}
                  onClick={() => {
                    updateUserNotificationPreference({
                      variables: {
                        input: {
                          id: option.sourcePreference,
                          source: option.sourcePreference,
                          enabled: !isEnabled,
                        },
                      },
                    });
                  }}
                  isSelected={isEnabled}
                  name={option.label}
                />
              );
            })}
          </OptionsWrapper>
        </OptionsDiv>
      )}
    </Flex>
  );
}
