import React from "react";
import styled from "styled-components";
import { Switch } from "app/components/Switch";

const Wrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  padding: 12px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.p`
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  margin-bottom: 5px;
`;

interface Props {
  name: string;
  isSelected: boolean;
  onClick: () => void;
}

export function NotificationToggle({ name, isSelected, onClick }: Props) {
  return (
    <Wrapper onClick={onClick}>
      <div>
        <Name>{name}</Name>
      </div>
      <Switch checked={isSelected} />
    </Wrapper>
  );
}
