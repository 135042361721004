import styled from "styled-components";

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 57px;
  height: 29px;
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  :checked + span {
    background-color: ${({ theme }) => theme.colors.primary[0]};

    :before {
      background-color: ${({ theme }) => theme.colors.white};
      -webkit-transform: translateX(27px);
      -ms-transform: translateX(27px);
      transform: translateX(27px);
    }
  }

  :focus + span {
    box-shadow: 0 0 1px ${({ theme }) => theme.colors.grey};
  }
`;

interface SliderProps {
  disabled?: boolean;
}

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.grey};
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 50px;

  ${({ disabled }: SliderProps) =>
      disabled &&
      `
    cursor: not-allowed;
  `}
    :before {
    box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
    border-radius: 50px;
    content: "";
    height: 25px;
    position: absolute;
    left: 2px;
    bottom: 2px;
    width: 26px;
    background-color: ${({ theme }) => theme.colors.white};
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
`;
