import React from "react";
import { NotificationPreference } from "services/graphql";
import Flex from "app/components/Flex";
import { Title } from "../styles";
import { PushNotificationActivitySetting } from "./PushNotificationActivitySetting";
/* Reminders are currently not set up at the moment */
// import { PushNotificationReminderSetting } from "./PushNotificationReminderSetting";

interface Props {
  notificationPreferences: NotificationPreference[];
}

export function PushNotificationSettings({ notificationPreferences }: Props) {
  return (
    <Flex flexDirection="column">
      <Title>Push Notifications</Title>
      <PushNotificationActivitySetting
        notificationPreferences={notificationPreferences}
      />
      {/* Reminders are currently not set up at the moment */}
      {/* <PushNotificationReminderSetting
        notificationPreferences={notificationPreferences}
      /> */}
    </Flex>
  );
}
